import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { ResponseSetStatus, HistoryItemType } from "@/model/Enums";

export interface IWorkflowHistory {
    responseSetID: number;
    responseSetStatus: ResponseSetStatus;
    type: HistoryItemType;
    notes: string;
    createdDate: Date;
    createdByUserID: number;
}

export class WorkflowHistory implements IWorkflowHistory {

    constructor(data?: IWorkflowHistory) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IWorkflowHistory) {
        mapData(data, { root: () => this });
    }

    responseSetID: number = 0;
    responseSetStatus: ResponseSetStatus = 0;
    type: HistoryItemType = 0;
    notes: string = "";
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;

}