
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Authentication } from "@/stuff/Authentication";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { IFollowUp, FollowUp } from "@/model/FollowUp";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { TriState, UserRole, FollowUpType, LookupGroup } from "@/model/Enums";
    import { IUser } from "@/model/User";
    import { FollowUpSearchParameters } from "@/model/FollowUpSearchParameters"
    import utils from "@/stuff/Utils";
    import apiClient from "@/stuff/ApiClient";
    import * as toastr from "toastr";
    import { Watch } from "vue-property-decorator";

    @Component({ })
    export default class FollowUps extends Vue {

        async mounted() {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;

            await this.getLookups();
            await this.search();
        
            const user = (Authentication.signedInUser() as IUser);
            this.parameters.assignedToUserID = user.role === UserRole.Admin ? 0 : user.id;
        }
        
        // properties

        private isBuyerZone: boolean = false; // set when component loads
        private isSupplierZone: boolean = false; // set when component loads

        private users: Array<ILookupItem> = [];
        private supplierList: Array<ILookupItem> = [];
        private historyTypeList: Array<ILookupItem> = [];

        private itemsPerPage: number = 100;
        private readonly parameters = new FollowUpSearchParameters()
        
        private totalCount = -1;
        private followUps: Array<FollowUp> = [];

        // computed properties

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get supplierOptions(): Array<ILookupItem> {            
            const options = this.supplierList.filter(s => !s.isArchived);
            return utils.selectOptions(options, "All Suppliers"); 
        }

        private get historyTypeOptions(): Array<ILookupItem> { 
            const options = this.historyTypeList.filter(s => !s.isArchived);
            return utils.selectOptions(options, "Any History Type"); 
        }

        private get userSearchOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            const allItem = new LookupItem({ id: 0, description: "All", isArchived: false } as ILookupItem);
            const unassignedItem = new LookupItem({ id: -1, description: "Unassigned", isArchived: false } as ILookupItem);
            options.push(allItem)
            options.push(unassignedItem)
            options.push(...this.users);
            return options;
        }

        private get assignToOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            options.push(...this.users);
            return options;
        }

        private get fields(): Array<any> {
            return [
                { key: "supplierID", label: "Supplier" },
                { key: "type", label: "Type" },
                { key: "historyType", label: "History Type" },
                { key: "followUpDate", label: "Follow Up By" },
                { key: "assignedToUserID", label: "Assign To" },
                { key: "actions", label: "" }
            ]
        }

        // watchers

        @Watch("parameters.historyTypeID")
        private onHistoryTypeChanged() {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.supplierID")
        private onSupplierChanged() {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.assignedToUserID")
        private onAssignedToChanged() {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.pageNumber")
        private onPageChanged() {
            this.search();
        }

        // methods

        private resetSearch() {
            utils.resetObject(this.parameters);
        }

        private sortIcon(column: string): string {
            if (column != this.parameters.orderBy)
                return "fa-sort"
            if (this.parameters.orderDir == "ASC")
                return "fa-sort-up";
            return "fa-sort-down"
        }

        private sort(column: string) {
            if (this.parameters.orderBy == column && this.parameters.orderDir == "ASC") {
                this.parameters.orderDir = "DESC"
            }
            else if (this.parameters.orderBy == column && this.parameters.orderDir == "DESC") {
                this.parameters.orderDir = "ASC"
            }
            else {
                this.parameters.orderBy = column;
                this.parameters.orderDir = "ASC"
            }

            this.parameters.pageNumber = 1
            this.search(event);
        }

        private async getLookups() {
            this.itemsPerPage = await apiClient.get("/api/lookup/followUpsPerPage")

            const params = new UserSearchParameters();
            params.isDeleted = TriState.False;
            params.isDormant = TriState.False;

            params.role = UserRole.Admin;
            let response = await apiClient.post("/api/user/searchLookups", params)
            this.users.push(...response)

            params.role = UserRole.Cqms;
            response = await apiClient.post("/api/user/searchLookups", params)
            this.users.push(...response)

            this.users.sort((a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0));
            
            this.supplierList = [];
            response = await apiClient.get("/api/supplier/lookups");
            this.supplierList.push(...response.map((i: ILookupItem) => new LookupItem(i)));

            this.historyTypeList = [];
            response = await apiClient.get(`/api/lookup/search/${LookupGroup.HistoryType}`)
            this.historyTypeList.push(...response.map((i: ILookupItem) => new LookupItem(i)));
        }

        private async search(event?: Event) {
            if (this.isBuyerZone || this.isSupplierZone) return;

            if (!utils.isEmptyId(this.parameters.pageNumber)) {
                const response = await apiClient.post("/Api/FollowUp/Search", this.parameters);
                this.totalCount = response.count;            
                this.followUps.length = 0;
                this.followUps.push(...response.list.map((f: IFollowUp) => new FollowUp(f)));
            }
        }

        private refreshSearch(event?: Event) {
            //utils.resetObject(this.parameters);
            this.parameters.pageNumber = 1
            this.search(event);
        }

        private goToSupplier(item: FollowUp, index: number, event: Event) {
            let tab = "";
            if (item.type == FollowUpType.Payment) tab = "payment";
            if (item.type == FollowUpType.History) tab = "history";
            if (item.type == FollowUpType.Communication) tab = "comms";

            const route = { 
                path: `/Supplier/${item.supplierID}?goToTab=${tab}`,
                query: { goToTab: tab }
            };
            window.open(route.path, "_blank");
            //this.$router.push(route);
        }

        private typeDescription(item: FollowUp) {
            if (item.type == FollowUpType.Payment)
                return "Payment";
            if (item.type == FollowUpType.History)
                return "History";
            if (item.type == FollowUpType.Communication && item.isBuyerCommunication)
                return "Communication (Buyer)";
            if (item.type == FollowUpType.Communication && !item.isBuyerCommunication)
                return "Communication (Supplier)";
        }

        private async deleteItem(followUp: FollowUp, event?: Event) {
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Are you sure you want to dimiss this follow up?", {
                title: "Dimiss Follow Up",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "No",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });

            if (!shouldDelete) return;

            const response = await apiClient.post("/api/followUp/delete", followUp, event);
            toastr.warning(response.message === "ok" ? "Deleted": "Failed to delete");
            this.refreshSearch();
        }

    }
