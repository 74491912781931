import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface IWorkflow {
    responseSetID: number;
    assignedToUserID: number;
    previouslyAssignedToUserID: number;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    responseSetSubmitted: Date;
    categoryID: number;
    supplierID: number;
    moduleID: number;
    status: string;
    fastTrack: boolean;
    newSupplier: boolean;
    auditNumber: number;
    assignedDate: Date;
    passedToAuditor: Date|null;
    deadline: Date|null;
    overdue: boolean;
    dueToday: boolean;
    detailsFlaggedAsIncorrect: boolean;
    questionCount: number;
    questionCountAccepted: number;
}

export class Workflow implements IWorkflow {

    constructor(data?: IWorkflow) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IWorkflow) {
        mapData(data, { root: () => this });
    }
    
    responseSetID: number = 0;
    assignedToUserID: number = 0;
    previouslyAssignedToUserID: number = 0;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    responseSetSubmitted: Date = new Date(utils.emptyDateValue);
    categoryID: number = 0;
    supplierID: number = 0;
    moduleID: number = 0;
    status: string = "";
    fastTrack: boolean = false;
    newSupplier: boolean = false;
    auditNumber: number = 0;
    sendEmail: boolean = false;
    assignedDate: Date = new Date(utils.emptyDateValue);
    passedToAuditor: Date|null = new Date(utils.emptyDateValue);
    deadline: Date|null = new Date(utils.emptyDateValue);
    overdue: boolean = false;
    dueToday: boolean = false;
    detailsFlaggedAsIncorrect: boolean = false;
    questionCount: number = 0;
    questionCountAccepted: number = 0;

    get rowVariant(): string {
        if (this.fastTrack) return "wf-fast-track";
        if (this.overdue) return "wf-overdue";
        if (this.dueToday) return "wf-due-today";
        return ""
    }

}