
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import Notifications from "@/components/Notifications.vue";
    import MessagesCommunications from "@/components/BuyerZoneSupplierTabs/MessagesCommunications.vue";
    import Workflows from "@/components/Workflows.vue";
    import FollowUps from "@/components/FollowUps.vue";
    import store from "@/store/store";
    import { IUser, User } from "@/model/User";
    import apiClient from "@/stuff/ApiClient";
       
    @Component({
        components: { 
            ApiButton, 
            Notifications, 
            MessagesCommunications, 
            Workflows,
            FollowUps
        }
    })
    export default class Home extends Vue {

        async created() {
            await this.getUser();
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.tabIndex = 0;
        }

        tabIndex: number = 0;
        private isBuyerZone: boolean = false; // set when component loads
        private isSupplierZone: boolean = false; // set when component loads

        private user = new User();

        get shouldShowCommumications(): boolean { 
            return this.isBuyerZone; 
        }

        get shouldShowAuditWorkflows(): boolean {
            return this.user.isAuditor && !this.isBuyerZone && !this.isSupplierZone;
        }

        get shouldShowAdminWorkflows(): boolean {
            return this.user.isWorkflowAdmin && !this.isBuyerZone && !this.isSupplierZone;
        }

        get shouldShowFollowUps(): boolean {
            return !this.isBuyerZone && !this.isSupplierZone;
        }

     refreshWorkflows() {
            if (this.$refs.adminWF) {
                const tab = this.$refs.adminWF as Workflows;
                tab.refreshSearch()
            }

            if (this.$refs.auditWF) {
                const tab = this.$refs.auditWF as Workflows;
                tab.refreshSearch()
            }
        }

        private async getUser() {
            const response: IUser = await apiClient.get("/api/user/signedIn");
            this.user.update(response);
        }

    }
