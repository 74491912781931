export class FollowUpSearchParameters {

    id: number = 0;
    historyTypeID: number = 0;
    supplierID: number = 0;
    assignedToUserID: number = 0;
    pageNumber: number = 1;
    orderBy: string = "FollowUpBy";
    orderDir: string = "ASC";

}