import utils from "@/stuff/Utils";
import { WorkflowView } from "@/model/Enums";

export class WorkflowSearchParameters {

    id: number = 0;
    supplierID: number = 0;
    moduleID: number = 0;
    statusID: number = 0;
    categoryID: number = 0;
    assignedToUserID: number = 0;
    previouslyAssignedToUserID: number = 0;
    submittedDate: Date = new Date(utils.emptyDateValue);
    deadlineDate: Date = new Date(utils.emptyDateValue);
    pageNumber: number = 1;
    view: WorkflowView = 0;

}