
    import Vue from "vue";
    import Component from "vue-class-component";
    import store from "@/store/store";
    import { Authentication } from "@/stuff/Authentication";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { CommunicationSearchParameters } from '@/model/CommunicationSearchParameters';
    import { Communication, ICommunication } from '@/model/Communication';
    import { StateChanger } from 'vue-infinite-loading';
    import { LookupItem } from "@/model/LookupItem";
    import { IUser } from "@/model/User";
    import { TriState } from "@/model/Enums";

    @Component({})
    export default class HomeCommunications extends Vue {

        mounted() {
            this.searchParameters.isBuyer = TriState.True;
            this.searchParameters.buyerID = (Authentication.signedInUser() as IUser).buyerID;
            this.searchParameters.userID = (Authentication.signedInUser() as IUser).id;
            this.searchParameters.hasBeenOpened = TriState.False;
            store.dispatch("loadSupplierList");
            this.getCount();
        }

        searchParameters = new CommunicationSearchParameters();
        totalCount = -1;
        communicationList: Array<ICommunication> = [];
        private get supplierList(): Array<LookupItem> { return this.$store.state.supplierList; }

        get showNoneMessage(): boolean {
            return this.totalCount === 0;
        }

        get isImpersonationMode(): boolean { 
            return store.getters.isImpersonationMode; 
        }

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private communicationsLink(communication: Communication) {
            return { 
                path: `/buyerZone/supplier/${communication.supplierID}`, 
                query: { communicationID: communication.id.toString() } 
            };
        }

        private supplierName(communication: Communication): string {
            return utils.lookupDescription(communication.supplierID, this.supplierList);
        }

        refreshSearch(event?: Event) {
            this.communicationList = [];
            this.totalCount = -1;
            this.search(event);
        }

        private async getCount() {
            const response = await apiClient.post("/Api/Communication/Search", this.searchParameters, event);
            this.totalCount = response.count;
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Communication/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            // add new 'page' to existing list
            this.communicationList.push(...response.list.map((s: ICommunication) => new Communication(s)));
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.communicationList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.communicationList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async dismissMessage(communication: Communication) {
            await apiClient.post(`Api/Communication/dismiss`, communication);
            this.refreshSearch();
        }
    }
