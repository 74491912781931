
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/stuff/ApiClient";
    import { StateChanger } from 'vue-infinite-loading';
    import { NotificationSearchParameters } from '@/model/NotificationSearchParameters';
    import { Notification, INotification } from '@/model/Notification';

    @Component({})
    export default class Notifications extends Vue {

        mounted() {            
            this.getCount();
        }
        
        searchParameters = new NotificationSearchParameters();
        notificationList: Array<INotification> = [];
        totalCount = -1;

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private async getCount() {
            const response = await apiClient.post("/Api/Notification/Search", this.searchParameters, event);
            this.totalCount = response.count;
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Notification/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.notificationList.push(...response.list.map((s: INotification) => new Notification(s)));
        }

        refreshSearch(event?: Event) {
            this.notificationList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.notificationList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.notificationList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

    }

